import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _624115be = () => interopDefault(import('../pages/About.vue' /* webpackChunkName: "pages/About" */))
const _489f8ee8 = () => interopDefault(import('../pages/Banks.vue' /* webpackChunkName: "pages/Banks" */))
const _9bd782b2 = () => interopDefault(import('../pages/Buyout.vue' /* webpackChunkName: "pages/Buyout" */))
const _44ca80b2 = () => interopDefault(import('../pages/Contacts.vue' /* webpackChunkName: "pages/Contacts" */))
const _3510e003 = () => interopDefault(import('../pages/Cookie.vue' /* webpackChunkName: "pages/Cookie" */))
const _83bc7aca = () => interopDefault(import('../pages/Credit/index.vue' /* webpackChunkName: "pages/Credit/index" */))
const _4ccb4682 = () => interopDefault(import('../pages/Exchange.vue' /* webpackChunkName: "pages/Exchange" */))
const _6caf99f0 = () => interopDefault(import('../pages/Favorites.vue' /* webpackChunkName: "pages/Favorites" */))
const _584deb3c = () => interopDefault(import('../pages/Installments/index.vue' /* webpackChunkName: "pages/Installments/index" */))
const _2ffb7df9 = () => interopDefault(import('../pages/Privacy.vue' /* webpackChunkName: "pages/Privacy" */))
const _1e53aef5 = () => interopDefault(import('../pages/Reviews1111111.vue' /* webpackChunkName: "pages/Reviews1111111" */))
const _54a391e8 = () => interopDefault(import('../pages/Thanks.vue' /* webpackChunkName: "pages/Thanks" */))
const _469bb7e7 = () => interopDefault(import('../pages/Credit/_bank/index.vue' /* webpackChunkName: "pages/Credit/_bank/index" */))
const _0a86d0a3 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _5bcf7f9e = () => interopDefault(import('../pages/_category/index.vue' /* webpackChunkName: "pages/_category/index" */))
const _47356c4e = () => interopDefault(import('../pages/_category/_mark/index.vue' /* webpackChunkName: "pages/_category/_mark/index" */))
const _32b0a661 = () => interopDefault(import('../pages/_category/_mark/_model/index.vue' /* webpackChunkName: "pages/_category/_mark/_model/index" */))
const _12bcde59 = () => interopDefault(import('../pages/_category/_mark/_model/_car/index.vue' /* webpackChunkName: "pages/_category/_mark/_model/_car/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'active',
  linkExactActiveClass: 'active',
  scrollBehavior,

  routes: [{
    path: "/About",
    component: _624115be,
    name: "About"
  }, {
    path: "/Banks",
    component: _489f8ee8,
    name: "Banks"
  }, {
    path: "/Buyout",
    component: _9bd782b2,
    name: "Buyout"
  }, {
    path: "/Contacts",
    component: _44ca80b2,
    name: "Contacts"
  }, {
    path: "/Cookie",
    component: _3510e003,
    name: "Cookie"
  }, {
    path: "/Credit",
    component: _83bc7aca,
    name: "Credit"
  }, {
    path: "/Exchange",
    component: _4ccb4682,
    name: "Exchange"
  }, {
    path: "/Favorites",
    component: _6caf99f0,
    name: "Favorites"
  }, {
    path: "/Installments",
    component: _584deb3c,
    name: "Installments"
  }, {
    path: "/Privacy",
    component: _2ffb7df9,
    name: "Privacy"
  }, {
    path: "/Reviews1111111",
    component: _1e53aef5,
    name: "Reviews1111111"
  }, {
    path: "/Thanks",
    component: _54a391e8,
    name: "Thanks"
  }, {
    path: "/Credit/:bank",
    component: _469bb7e7,
    name: "Credit-bank"
  }, {
    path: "/",
    component: _0a86d0a3,
    name: "index"
  }, {
    path: "/:category",
    component: _5bcf7f9e,
    name: "category"
  }, {
    path: "/:category/:mark",
    component: _47356c4e,
    name: "category-mark"
  }, {
    path: "/:category/:mark/:model",
    component: _32b0a661,
    name: "category-mark-model"
  }, {
    path: "/:category/:mark/:model/:car",
    component: _12bcde59,
    name: "category-mark-model-car"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
